<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：info@21gmail.com
单元名称：新窗口页面的写信页面
开始时间：2021-05-08
开发人员：刘巍骏
最后修改：2021-05-08
备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 预览界面 -->
  <div class="priviewAll">
    <!-- 左边预览模板选择 -->
    <!-- <div class="priviewRight">
      <ul>
        <li>模板1</li>
        <li>模板2</li>
        <li>模板3</li>
        <li>模板4</li>
        <li>模板5</li>
      </ul>
    </div> -->

    <!-- 中间预览内容 -->
    <div class="priviewContent">
      <iframe src="https://www.baidu.com/" class="printViewIframe">

      </iframe>
    </div>

    <!-- 右边预览操作 -->
    <div class="priviewLeft">
      <ul>
        <li>打印</li>
        <li>编辑</li>
        <li>转换pdf</li>
        <li>操作2</li>
        <li>操作3</li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "newPrintView",
    data() {
      return {}
    },
    created() {
      console.log(this.$route.query.val);
      return
      var description = "";
      let obj = this.$route.query.data;
      for (var i in obj) {
        var property = obj[i];
        description += i + " = " + property + "\n";
      }
      // console.log(description);
      // alert(description);
      // console.log(this.$route.query.data);
      console.log(this.obj2string(obj));
    },
    mounted() {},
    methods: {
      obj2string(o) {
        var r = [];
        if (typeof o == "string") {
          return "\"" + o.replace(/([\'\"\\])/g, "\\$1").replace(/(\n)/g, "\\n").replace(/(\r)/g, "\\r").replace(
            /(\t)/g, "\\t") + "\"";
        }
        if (typeof o == "object") {
          if (!o.sort) {
            for (var i in o) {
              r.push(i + ":" + obj2string(o[i]));
            }
            if (!!document.all && !/^\n?function\s*toString\(\)\s*\{\n?\s*\[native code\]\n?\s*\}\n?\s*$/.test(o
                .toString)) {
              r.push("toString:" + o.toString.toString());
            }
            r = "{" + r.join() + "}";
          } else {
            for (var i = 0; i < o.length; i++) {
              r.push(obj2string(o[i]))
            }
            r = "[" + r.join() + "]";
          }
          return r;
        }
        return o.toString();
      }
    },
    components: {}
  }
</script>
<style lang="less">
  // 打印预览总框
  .priviewAll {
    // width: 100%;
    height: 80vh;
    display: flex;
    padding: 0;
    justify-content: space-between;
    border: 1px solid red;

    .priviewRight {
      width: 100px;
      border: 1px solid black;

      ul {
        padding: 10px;

        li {
          padding: 10px;
          text-align: center;
          margin-bottom: 10px;
          border-radius: 5px;
          background: @theme;
          color: white;
          // border: 1px solid black;
        }
      }
    }

    // 中间预览内容
    .priviewContent {
      width: calc(100% - 200px);
      border: 1px solid black;
      .printViewIframe{
        width: 100%;
        height: 100%;
      }
    }

    // 右边预览操作
    .priviewLeft {
      width: 100px;
      border: 1px solid black;

      ul {
        padding: 10px;

        li {
          padding: 10px;
          text-align: center;
          margin-bottom: 10px;
          border-radius: 5px;
          background: @theme;
          color: white;
          // border: 1px solid black;
        }
      }
    }
  }



  /* 打印时需要隐藏的div */
  @media print {

    /* 模板选择框 */
    .modelSelect {
      display: none;
    }

    /* 打印操作框 */
    .priviewOperate {
      display: none;
    }
  }
</style>
<!-- <style src="@/css/order/ordermodel/ordercomm.css" lang="less"></style> -->
